import axios from "axios";
import { useState } from "react";
// constantes
const dataInicial = {
  properties: [''],
  property: {},
  featured:[],
  favorites: [],
  loading: true,
  loading_more:false,
  searchHome:{
    operation:'venta', 
    location:[],
    type:[],  
    address:""
  },
  filters: {
    location: [],
    type: [],
    operation: "",
    environments:'',
    bedrooms:'',
    age:'',
    address:'',
    producer_id:'',
    price:{
      type:'',
      priceMin:'',
      priceMax:'',
      is_off:false,
    },
    surface:{
      type:'',
      surfaceMin:"",
      surfaceMax:"",
    },
    environments_types: [],
    generals: [],
    services: [],
    specials: [],
  },
  orders: {
    order: "desc",
    order_by: "id",
  },
  totalListFilters: {
    location: [],
    type: [],
    operation: [],
    environments: [],
    price: [],
    surface: [],
    environments_types: [],
    generals: [],
    services: [],
    specials: [],
  },
  listFilters: {
    location: [],
    type: [],
    operation: [],
    environments: [],
    price: [],
    surface: [],
    environments_types: [],
    generals: [],
    services: [],
    specials: [],
  },
  offset: 12,
  totalCount:0,
  allLocationFilters:[],
  loading_locatons:false,
  updaterFavorites:false,
  currentlyImage:1, // Lightbox global
  currentlyBluePrint:1, // Lightbox global
  togglerImages:false, // Lightbox global
  togglerVideos:false,
  togglerTours:false,
  togglerBlueprints:false,
};


// types
const LOADING = "LOADING";
const LOADING_MORE = "LOADING_MORE";
const CHANGE_TYPE = "CHANGE_TYPE";
const UPDATE_FILTERS = "UPDATE_FILTERS";
const UPDATE_ORDERS = "UPDATE_ORDERS";
const LIST_FILTERS = "LIST_FILTERS";
const TOTAL_LIST_FILTERS = "TOTAL_LIST_FILTERS";
const GET_PROPERTY = "GET_PROPERTY";
const GET_SIMILAR_PROPERTIES = "GET_SIMILAR_PROPERTIES";
const GET_FAV_PROPERTY = "GET_FAV_PROPERTY";
const CLEAR_FAV_PROPERTY = "CLEAR_FAV_PROPERTY";
const DELETE_FAV_PROPERTY = "DELETE_FAV_PROPERTY";
const GET_PROPERTIES = "GET_PROPERTIES";
const GET_FULL_PROPERTIES = "GET_FULL_PROPERTIES";
const GET_MORE_PROPERTIES = "GET_MORE_PROPERTIES";
const GET_FEATURED_PROPERTIES = "GET_FEATURED_PROPERTIES";
const CLEAR_PROPERTIES = "CLEAR_PROPERTIES";
const CLEAR_PROPERTY = "CLEAR_PROPERTY";
const GET_ALL_LOCATION = "GET ALL_LOCATION";
const UPDATER_FAVORITES = "UPDATER_FAVORITES";
const LOADING_LOCATIONS = "LOADING_LOCATIONS";
const UPDATE_OFFSET = "UPDATE_OFFSET";

const SET_SEARCH_HOME_OPERATION = "SET_SEARCH_HOME_OPERATION";
const SET_SEARCH_HOME_TYPE = "SET_SEARCH_HOME_TYPE";
const SET_SEARCH_HOME_LOCATION = "SET_SEARCH_HOME_LOCATION";
const SET_SEARCH_HOME_ADDRESS = "SET_SEARCH_HOME_ADDRESS";

const GET_TOTALCOUNT = "GET_TOTALCOUNT";

const SET_CURRENTLY_BLUEPRINT = "SET_CURRENTLY_BLUEPRINT"
const SET_CURRENTLY_IMAGES = "SET_CURRENTLY_IMAGES"
const SET_TOGGLER_IMAGES = "SET_TOGGLER_IMAGES"
const SET_TOGGLER_VIDEOS = "SET_TOGGLER_VIDEOS"
const SET_TOGGLER_TOURS = "SET_TOGGLER_TOURS"
const SET_TOGGLER_BLUEPRINTS = "SET_TOGGLER_BLUEPRINTS"

// reducer
export default function propertiesReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: true };
    case LOADING_MORE:
      return { ...state, loading_more: true };
    case CHANGE_TYPE:
      return { ...state, listed: action.payload };
    case UPDATE_FILTERS:
      return { ...state, filters: action.payload };
    case UPDATE_ORDERS:
      return { ...state, orders: action.payload };
    case TOTAL_LIST_FILTERS:
      return { ...state, totalListFilters: action.payload };
    case LIST_FILTERS:
      return { ...state, listFilters: action.payload };
    case GET_FULL_PROPERTIES:
      return { ...state, fullProperties: action.payload, loading: false };
    case GET_PROPERTY:
      return { ...state, property: action.payload.data, loading: false };
    case GET_FAV_PROPERTY:
      return {
        ...state,
        favorites: [...state.favorites, action.payload.data],
        loading: false,
      };
    case DELETE_FAV_PROPERTY:
      return {
        ...state,
        favorites: [...state.favorites, action.payload],
        loading: false,
      };
    case CLEAR_FAV_PROPERTY:
      return { ...state, favorites: [], loading: false };
    case GET_PROPERTIES:
      return { ...state,
        properties: action.payload.data.objects,
        totalCount: action.payload.data.meta.total_count,
        loading:false };
    case GET_MORE_PROPERTIES:
      return {
        ...state,
        properties: action.payload,
        offset: state.offset + 12,
        loading_more: false,
      };
    case GET_FEATURED_PROPERTIES:
      return { ...state, featured: action.payload,loading:false };
    case CLEAR_PROPERTIES:
      return { ...state, properties: [], loading: false };
    case CLEAR_PROPERTY:
      return { ...state, property: {}, loading: false };
    case GET_ALL_LOCATION:
      return { ...state, allLocationFilters: action.payload, loading_locatons:false };
    case UPDATER_FAVORITES:
      return { ...state, updaterFavorites: !state.updaterFavorites };
    case LOADING_LOCATIONS:
        return { ...state, loading_locatons: true };
    case UPDATE_OFFSET:
      return { ...state, offset: action.payload };
    case SET_SEARCH_HOME_OPERATION:
      return { ...state, searchHome: {...state.searchHome, operation:action.payload}};
    case SET_SEARCH_HOME_TYPE:
      return { ...state, searchHome: {...state.searchHome, type:action.payload}};
    case SET_SEARCH_HOME_ADDRESS:
      return { ...state, searchHome: {...state.searchHome, address:action.payload}};
    case SET_SEARCH_HOME_LOCATION:
      return { ...state, searchHome: {...state.searchHome, location:action.payload}};
    case SET_CURRENTLY_BLUEPRINT:
      return { ...state, currentlyBluePrint: action.payload, };
    case SET_CURRENTLY_IMAGES:
      return { ...state, currentlyImage: action.payload, };
    case SET_TOGGLER_IMAGES:
      return { ...state, togglerImages: !state.togglerImages };
    case SET_TOGGLER_VIDEOS:
      return { ...state, togglerVideos: !state.togglerVideos };
    case SET_TOGGLER_TOURS:
      return { ...state, togglerTours: !state.togglerTours };
    case SET_TOGGLER_BLUEPRINTS:
      return { ...state, togglerBlueprints: !state.togglerBlueprints };
    default:
      return state;
  }
}
// actions
export const changeTypeList = (type) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_TYPE,
    payload: type,
  });
};
export const getTotalListFilters = () => async (dispatch, getState) => {
  const summary_filters = (JSON.stringify({
    "current_localization_id":[], 
    "current_localization_type": "",
    // "currency":"",
    "price_from":0,
    "price_to":9999999999999,
    "operation_types":[1,2,3],
    "property_types":[]
  }))
    try {
      const res = await axios.get(
        "https://tokkobroker.com/api/v1/property/get_search_summary/?key=" +
          getState().settings.keys.tokko + 
          "&lang=es_ar&format=json&data=" + summary_filters
        );
      dispatch({
        type: TOTAL_LIST_FILTERS,
        payload: res,
      });
    } catch (error) {
      console.log(error);
    }
};
export const getListFilters = () => async (dispatch, getState) => {
  const filters = getState().properties.filters;
  
  const operation_id = [
    { name: "venta", id: 1 },
    { name: "alquiler", id: 2 },
    { name: "alquiler temporario", id: 3 },
    { name: "alquiler-temporario", id: 3 },
  ];
  const operation = filters.operation ? [operation_id.find((element) => element.name === filters.operation.toLowerCase()).id] : [];
  const types = filters.type ? filters.type.map(element => element.id) : [];
  const locations = filters.location ? filters.location.map(element => element.id) : [];
  const currency = filters.price.type === 'dolares' ? 'USD' : filters.price.type === 'pesos' ? 'ARS' : '';
  const price_from = filters.price.priceMin ? filters.price.priceMin : 0;
  const price_to = filters.price.priceMax ? filters.price.priceMax : 0;
  if(operation){
    const summary_filters = (JSON.stringify({
      "current_localization_id":locations, 
      "current_localization_type": locations.length > 0 ? 'division' : '',
      // "currency":currency,
      "price_from":price_from,
      "price_to":price_to,
      "operation_types":operation,
      "property_types":types
    }))
    try {
      const res = await axios.get(
        "https://tokkobroker.com/api/v1/property/get_search_summary/?key=" +
          getState().settings.keys.tokko + 
          "&lang=es_ar&format=json&data=" + encodeURI(summary_filters)
        );
      dispatch({
        type: LIST_FILTERS,
        payload: res,
      });
    } catch (error) {
      console.log(error);
    }
  }
};
// Properties
export const getPropertyAction = (id) => async (dispatch, getState) => {
  dispatch({type: LOADING});
  try {
    const res = await axios.get(
      " https://api-mh.mediahaus.com.ar/api/v1/tokko/properties/" +
        id +
        "?cache=true",
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko
        },
      }
    );
    dispatch({
      type: GET_PROPERTY,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getSimilarPropertiesAction = (property) => async (dispatch, getState) => {
  if(property?.id){
    dispatch({
      type: LOADING,
    });

    const operation_id = [
      { name: "Venta", id: 1 },
      { name: "Alquiler", id: 2 },
      { name: "Temporario", id: 3 },
    ];

    let params_get = "";
    // Operacion
    params_get += "&operation=" + operation_id.find((element) => element.name === property.operations[0].operation_type.toLowerCase())?.id;
    // Tipo
    params_get += "&tipo=" + property.type.id;
    // Location
    params_get +=
    "&localization-divisions=" +
    property.location.id +
    "&localization-type=division";

  // if (price.type) {
    var price_type = "";
    switch (property.operations[0].prices[0].currency) {
      case "ARS": {
        price_type = "ars";
        break;
      }
      default:
        price_type = "usd";
    }

    const priceMin = property.operations[0].prices[0].price ? property.operations[0].prices[0].price * 0.8 : 0;
    const priceMax = property.operations[0].prices[0].price ? property.operations[0].prices[0].price * 1.2 : 100000;

    params_get +=
      "&currency=" +
      price_type +
      "&price=" +
      priceMin +
      "-" +
      priceMax
  // }    

  // - Que sea en el misma location
  // - Mismo tipo operación
  // - Tipo de propiedad
  // - Rango de precio + - 20%

    try {
      const res = await axios.get(
        "https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/search?cache=0&save=0&limit=10&offset=0" + params_get ,
        {
          headers: {
            "X-TOKKO-KEY": getState().settings.keys.tokko
          },
        }
      );
      dispatch({
        type: GET_SIMILAR_PROPERTIES,
        payload: res,
      });
    } catch (error) {
      console.log(error);
    }
  }

};
export const getFavoritesAction = (id) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  if (
    getState().properties.favorites.find(
      (element) => element.data.id.toString() === id.toString()
    )
  ) {
    const new_favs = getState().properties.favorites.filter(
      (element) => element.data.id.toString() !== id.toString()
    );
    dispatch({
      type: DELETE_FAV_PROPERTY,
      payload: new_favs,
    });
  }
  try {
    const res = await axios.get(
      " https://api-mh.mediahaus.com.ar/api/v1/tokko/properties/" +
        id +
        "?cache=true",
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko
        },
      }
    );
    dispatch({
      type: GET_FAV_PROPERTY,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};
export const clearFavoritesAction = (id) => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_FAV_PROPERTY,
  });
};
export const updaterFavoritesAction = () => async (dispatch, getState) => {
  dispatch({type:UPDATER_FAVORITES})
}
export const clearPropertiesAction = (id) => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_PROPERTIES,
  });
};
export const clearPropertyAction = () => async (dispatch, getState) => {
  dispatch({
    type: CLEAR_PROPERTY,
  });
};
export const getAllLocationsAction = () => async (dispatch, getState) => {
  dispatch({type: LOADING_LOCATIONS})
  const summary_filters = (JSON.stringify({
    "current_localization_id":[], 
    "current_localization_type": "",
    "price_from":0,
    "price_to":9999999999999,
  }))
  try {
    const res = await axios.get(
     //https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/locations  // Template
     //https://api-mh.mediahaus.com.ar/api/v1/tokko/properties/locations  // Old
      "https://api-mh.mediahaus.com.ar/api/v1/tokko/properties/locations" +
      "?lang=es_ar&format=json&data=" + encodeURI(summary_filters),{
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko
      }});
      dispatch({type:GET_ALL_LOCATION,payload:res.data})
  }
  catch (error) {
    console.log(error);
  }
}
export const getPropertiesAction = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  const filters = getState().properties.filters;
  const orders = getState().properties.orders;
  var params_get = "";
  var tags_both = [];
  const operation_id = [
    { name: "venta", id: 1 },
    { name: "alquiler", id: 2 },
    { name: "alquiler temporario", id: 3 },
    { name: "alquiler-temporario", id: 3 },
  ];
  const environments = [
    { name: "1 ambiente", id: 1 },
    { name: "2 ambientes", id: 2 },
    { name: "3 ambientes", id: 3 },
    { name: "4 ambientes", id: 4 },
    { name: "5 ambientes", id: 5 },
    { name: "6 ambientes", id: 6 },
  ];
  if (filters.operation) {
    params_get += "&operation=" + operation_id.find((element) => element.name === filters.operation.toLowerCase()).id;
  }
  if (filters.type.length > 0) {
    params_get += "&tipo=" + filters.type.map((element) => element.id);
  }
  if (filters.location.length > 0) {
    params_get +=
      "&localization-divisions=" +
      filters.location.map((element) => element.id) +
      "&localization-type=division";
  }
  if (filters.services.length > 0) {
    tags_both.push(filters.services.map((element) => element.id));
  }
  if (filters.generals.length > 0) {
    tags_both.push(filters.generals.map((element) => element.id));
  }
  if (filters.environments_types.length > 0) {
    tags_both.push(filters.environments_types.map((element) => element.id));
  }
  if (filters.environments_types.length > 0 ||filters.generals.length ||filters.services.length > 0) {
    params_get += "&tags=" + tags_both;
  }
  if (filters.surface.type) {
    var type_surface = "";
    const surfaceMin = filters.surface.surfaceMin ? filters.surface.surfaceMin : 0;
    const surfaceMax = filters.surface.surfaceMax ? filters.surface.surfaceMax : 999999999;
    if (filters.surface.type.toLowerCase() == "cubierta") {
      type_surface = "roofed";
    } 
    else{
      type_surface = filters.surface.type;
    } 
    params_get +=
      "&surface-type=" +
      type_surface +
      "&surface=" +
      surfaceMin+
      "-" +
      surfaceMax;
  }
  if (filters.price.type) {
    var price_type = "";
    switch (filters.price.type) {
      case "pesos": {
        price_type = "ars";
        break;
      }
      default:
        price_type = "usd";
    }

    const priceMin = filters.price.priceMin ? filters.price.priceMin : 0;
    const priceMax = filters.price.priceMax ? filters.price.priceMax : 10000000;

    params_get +=
      "&currency=" +
      price_type +
      "&price=" +
      priceMin +
      "-" +
      priceMax
  }
  if (filters.environments.length > 0) {
    var curenvironmets = [];
    for (const iterator of filters.environments) {
      curenvironmets.push(environments.find((element) => element.name === iterator.toLowerCase()).id
      );
    }
    params_get += "&rooms=" + curenvironmets[0];
  }
  try {
    const res = await axios.get(
      " https://api-mh.mediahaus.com.ar/api/v1/tokko/properties/search?cache=false&save=false&limit=12&offset=0&order-by=" +
        orders.order_by +
        "&order=" +
        orders.order +
        "" +
        params_get,
      {
        headers: {
          // "X-TOKKO-KEY": getState().settings.keys.tokko,
          "X-TOKKO-KEY": getState().settings.keys.tokko
        },
      }
    );
    dispatch({
      type: GET_PROPERTIES,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getFullPropertiesAction = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  try {
    const res = await axios.get(
      " https://api-mh.mediahaus.com.ar/api/v1/tokko/properties/search?cache=false&save=false&limit=36&offset=0",
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko
        },
      }
    );
    dispatch({
      type: GET_FULL_PROPERTIES,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getAdd_PropertiesAction = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING_MORE,
  });
  const filters = getState().properties.filters;
  const orders = getState().properties.orders;
  var params_get = "";
  var tags_both = [];
  const operation_id = [
    { name: "venta", id: 1 },
    { name: "alquiler", id: 2 },
    { name: "alquiler temporario", id: 3 },
    { name: "alquiler-temporario", id: 3 },
  ];
  const environments = [
    { name: "1 ambiente", id: 1 },
    { name: "2 ambientes", id: 2 },
    { name: "3 ambientes", id: 3 },
    { name: "4 ambientes", id: 4 },
    { name: "5 ambientes", id: 5 },
    { name: "6 ambientes", id: 6 },
  ];
  if (filters.operation) {
    params_get += "&operation=" + operation_id.find((element) => element.name === filters.operation.toLowerCase()).id;
  }
  if (filters.type.length > 0) {
    params_get += "&tipo=" + filters.type.map((element) => element.id);
  }
  if (filters.location.length > 0) {
    params_get +=
      "&localization-divisions=" +
      filters.location.map((element) => element.id) +
      "&localization-type=division";
  }
  if (filters.services.length > 0) {
    tags_both.push(filters.services.map((element) => element.id));
  }
  if (filters.generals.length > 0) {
    tags_both.push(filters.generals.map((element) => element.id));
  }
  if (filters.environments_types.length > 0) {
    tags_both.push(filters.environments_types.map((element) => element.id));
  }
  if (filters.environments_types.length > 0 ||filters.generals.length ||filters.services.length > 0) {
    params_get += "&tags=" + tags_both;
  }
  if (filters.surface.type) {
    var type_surface = "";
    const surfaceMin = filters.surface.surfaceMin ? filters.surface.surfaceMin : 0;
    const surfaceMax = filters.surface.surfaceMax ? filters.surface.surfaceMax : 999999999;
    if (filters.surface.type.toLowerCase() == "cubierta") {
      type_surface = "roofed";
    } 
    else{
      type_surface = filters.surface.type;
    } 
    params_get +=
      "&surface-type=" +
      type_surface +
      "&surface=" +
      surfaceMin+
      "-" +
      surfaceMax;
  }
  if (filters.price.type) {
    var price_type = "";
    switch (filters.price.type) {
      case "pesos": {
        price_type = "ars";
        break;
      }
      default:
        price_type = "usd";
    }

    const priceMin = filters.price.priceMin ? filters.price.priceMin : 0;
    const priceMax = filters.price.priceMax ? filters.price.priceMax : 10000000;

    params_get +=
      "&currency=" +
      price_type +
      "&price=" +
      priceMin +
      "-" +
      priceMax
  }
  if (filters.environments.length > 0) {
    var curenvironmets = [];
    for (const iterator of filters.environments) {
      curenvironmets.push(environments.find((element) => element.name === iterator.toLowerCase()).id
      );
    }
    params_get += "&rooms=" + curenvironmets[0];
  }
  try {
    const res = await axios.get(
      "https://api-mh.mediahaus.com.ar/api/v1/tokko/properties/search?cache=false&save=false&limit=12&offset=" +
        getState().properties.offset +
        "&order-by=" +
        orders.order_by +
        "&order=" +
        orders.order +
        params_get,
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko
        },
      }
    );
    var response = getState().properties.properties;
    for (const iterator of res.data.objects) {
      response.push(iterator);
    }
    dispatch({
      type: GET_MORE_PROPERTIES,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  }
};
export const getFeaturedPropertiesAction = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  try {
    const res = await axios.get(
      "https://api-mh.mediahaus.com.ar/api/v1/tokko/properties/search?cache=false&save=false&limit=24&offset=0&destacados=true",
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko,
        },
      }
    );
    dispatch({
      type: GET_FEATURED_PROPERTIES,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};

export const REDUX_UPDATE_POLYGON_COORDINATES = (coordinates) => (dispatch, getState) => {
  // if(coordinates.length > 0){
  //   let { filters } = getState().properties;
  //   filters.location = []
  //   REDUX_UPDATE_FILTERS(filters)
  // }
  dispatch({
    type: UPDATE_POLYGON_COORDINATES,
    payload: coordinates,
  });
}
export const REDUX_UPDATE_DRAWN_POLYGON = (polygon) => (dispatch, getState) => {
  dispatch({
    type: DRAWN_POLYGON,
    payload: polygon,
  });
}
export const REDUX_UPDATE_FILTERS = (_filters, toggleOperation = '') => (dispatch, getState) => {
  if(toggleOperation){
    if(toggleOperation === 'ALQUILER TEMP.'){
      _filters.operation = 'alquiler-temporario'
    }
    else{
      _filters.operation = toggleOperation;
    }
  }
  if(_filters !== getState().properties.filters) {
    dispatch({type:UPDATE_OFFSET,payload:0})
    dispatch({
      type: UPDATE_FILTERS,
      payload: _filters,
    });
  }
};
export const redux_updateOrders = (orders) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_ORDERS,
    payload: orders,
  });
};

export const setFilterHome = ({operation,type,location,address}) => async (dispatch, getState) => {
  dispatch({
    type: SET_SEARCH_HOME_OPERATION,
    payload:operation
  });
  dispatch({
    type: SET_SEARCH_HOME_TYPE,
    payload:type
  });
  dispatch({
    type: SET_SEARCH_HOME_LOCATION,
    payload:location
  });
  dispatch({
    type: SET_SEARCH_HOME_ADDRESS,
    payload:address
  });
};
export const getResumeTotalCount = (params,location_id = null) => async (dispatch, getState) => {
  // if(!getState().properties.loading_count){
    dispatch({
      type: LOADING_COUNT,
    });
    const filters = params
    var params_get = "";
    if(filters.operation.toString() === '' ){return null}
    const operation_id = [
      { name: "Tipo de operación", id:"1,2,3"},
      { name: "tipo de operación", id:"1,2,3"},
      { name: "tipo-de-operación", id:"1,2,3"},
      { name: "venta", id: 1 },
      { name: "alquiler", id: 2 },
      { name: "alquiler temporario", id: 3 },
      { name: "alquiler-temporario", id: 3 },
    ];
  
    if (filters.operation) {
      params_get += "&operation=" + operation_id.find((element) => element.name === filters.operation.toLowerCase()).id;
    }
    if (filters.type.length > 0) {
      params_get += "&tipo=" + filters.type.map((element) => element.id);
    }
    if (filters.location.length > 0) {
      params_get +=
        "&localization-divisions=" +
        filters.location.map((element) => element.id) +
        "&localization-type=division";
    }
    
    
    try {
      const res = await axios.get(
        "https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/search?cache=&save=0&limit=1&offset=0&" +
          "" +
          params_get,
        {
          headers: {
            "X-TOKKO-KEY": getState().settings.keys.tokko
          },
        }
      );
      if(getState().properties.totalCountAll === 0){
        dispatch({type:GLOBAL_TOTAL_COUNT,payload:res.data.meta.total_count})
      }
      dispatch({
        type: GET_TOTALCOUNT,
        payload: res.data.meta.total_count,
      });
    } catch (error) {
    console.log(error);
  }
// }
};

export const getPropertiesByAddress =  () => async (dispatch, getState) => {
    let params_get = ''
    const {operation,address,type,location} = getState().properties.searchHome
    const types = []
    if(address.length < 3){return null}
    if(!getState().properties.fetching_properties){
      dispatch({
        type: FETCHING_PROPERTIES,
      });
      const operation_id = [
        { name: "", id:"1,2,3"},
        { name: "Tipo de operación", id:"1,2,3"},
        { name: "tipo de operación", id:"1,2,3"},
        { name: "tipo-de-operación", id:"1,2,3"},
        { name: "venta", id: 1 },
        { name: "alquiler", id: 2 },
        { name: "alquiler temporario", id: 3 },
        { name: "alquiler-temporario", id: 3 },
      ];
      if (operation) {
        params_get += "&operation=" + operation_id.find((element) => element.name === operation.toLowerCase()).id;
      }
      if (location.length > 0) {
        params_get +=
          "&localization-divisions=" +
          location.map((element) => element.id) +
          "&localization-type=division";
      }
      if(type){
        const total_filters = getState().properties.totalListFilters;
        if (type?.length > 0) {
          const total_filters = getState().properties.totalListFilters;
          for(let i = 0; i < type.length; i++) {
            const id_type = type[i] !== '' ? [total_filters.data.objects.property_types.find(element => element?.type?.toLowerCase() === type[i]?.toLowerCase())?.id] : [];
            types.push(id_type);
          }
          params_get += "&tipo=" + types.join(", ");
        }
        // const id_types = type !== '' && type !== 'all' ? [total_filters.data.objects.property_types.find(element => element?.type?.toLowerCase() === type?.toLowerCase())?.id] : [];
        // if(id_types.length > 0){
        //   params_get+= "&tipo=" + id_types
        // }
      }
      if(address){
        params_get += "&fake_address=" + address
      }
    
      try {
        const res = await axios.get(
          "https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/search?cache=0&save=0&limit=100&offset=0&" +
            "" +
            params_get,
          {
            headers: {
              "X-TOKKO-KEY": getState().settings.keys.tokko
            },
          }
        );
        dispatch({
          type: GET_PROPERTIES_HOME,
          payload: res?.data?.objects,
        });
      } catch (error) {
      console.log(error);
    }}
}
export const getLatestPropertiesAction = () => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
  });
  try {
    const res = await axios.get(
      "https://dv-tokko.mediahaus.com.ar/api/v1/tokko/properties/search?cache=0&save=1&limit=6&offset=0&order=desc&order-by=id",
      {
        headers: {
          "X-TOKKO-KEY": getState().settings.keys.tokko,
        },
      }
    );
    dispatch({
      type: GET_FEATURED_PROPERTIES,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};
export const changeOffsetPropertiesAction = (offset) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_OFFSET,
    payload: offset,
  });
};
export const deleteFavoriteAction = (id) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_FAV_PROPERTY,
    payload:id
  });
};
export const setTogglerImages = () => async (dispatch) => {
  dispatch({type: SET_TOGGLER_IMAGES});
}
export const setTogglerVideos = () => async (dispatch) => {
  dispatch({type: SET_TOGGLER_VIDEOS});
}
export const setTogglerTours = () => async (dispatch) => {
  dispatch({type: SET_TOGGLER_TOURS});
}
export const setTogglerBlueprints = () => async (dispatch) => {
  dispatch({type: SET_TOGGLER_BLUEPRINTS});
}
export const setCurrentlyBlueprint = (value) => async (dispatch) => {
  dispatch({type: SET_CURRENTLY_BLUEPRINT, payload: value});
}
export const setCurrentlyImage = (value) => async (dispatch) => {
  dispatch({type: SET_CURRENTLY_IMAGES, payload: value});
}