import axios from 'axios';

// constantes
const dataInicial = {
    name:'Laura Farías',
    short_name:'LauraFarias',
    base_url:'/',
    // base_url:'/clientes/agostina-filo/',
    client_id:17,

    social:
    {
        instagram:'',
        facebook:'',
        twitter:'',
        linkedin:'',
        youtube:'',
    }
    ,
    contact:
    {
        phone:['' , ''],
        whatsapp:{link:'',visual:''},
        mail:''
        
    },
    location:
    {
        address:"",
        region:'',
        cp:""
    },
    keys:{
        captcha:'6Lfmez4dAAAAAPRBJgBY1CDZNZIBBCks2ZaIy-M6',
        tokko:'',
        // tokko:'7d5dc24fcb137714860c3c5924cf3ac62acb3b9a',
        google_tgm:'',
        google_map:'AIzaSyDTDrxQCHUV0hBG77bCeLJVLt8xw6uXsek',
        mediacore: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiZDJhMmZkZWMxZmJkZWE3OTA2N2U5M2QxZjkzY2YzMmE3NDhkZjk4Mzc2ZmFiNzAyZTlmMmE5MjQ3NWQ0MGYxNjdmOGFjN2FhZjc3ZTI3MWQiLCJpYXQiOjE2NjY4MDA2OTIsIm5iZiI6MTY2NjgwMDY5MiwiZXhwIjoxNjk4MzM2NjkyLCJzdWIiOiIxMTgiLCJzY29wZXMiOlsicG9zdHMiLCJjYXRlZ29yaWVzIiwidGFncyIsInVzZXJzIiwiY2xpZW50cyIsImZpbGVzIiwiZ3JvdXBzIl19.mfU7XWvn6BpcUh-rp8uP23X2kLjk0n3NlLsWdjYhru_G5dfXWOWUp8beyxqltV9mYoVp1t6KUjahdyDr8QCcBVx2LuDZMWWr4YBs6LypKNOgrFQpWOeWHrZRE88in08G6sLoPlPsdKIFgdUgIfzgStTq0iVNgnl1f6au97gDfZq-yFBCCp3lrypAGMqZLT3jpZtGLyuGHIMF--WhE2Mw-fbwEl1-HgeA6lsEANq_6LVF_wvTO65PSldPG5kGWNFAansUxOnrlTq0WdKvLSQPcNaTYOy_Z2-C0Cy5ZBPO1SEavWS4da3164YOHQMD1WyrU2_i2Dy_qCySmFx90O0fJEqT45UnjnLvdwrS1kkrDLteAofwSLRCmUksoV5ysbhhIfwfM0zop83gUa_-p596GdbEkScqSl-p0hQVPd_uO5tJTMY9Zxww6YYhM4usCGgdnWdflWSJ-0jBs471klUBF750imHiBaRcu9b47Ua-3iM5B4LeRWBboRh7a1ab5MX9KSUO5AHxi-ZZ66Bv7fBmfFVMXH15fey5I_ZEfAuCBeWL2oF2MeatBpf6quxqTop-mUeuTN-ywgzBcdKCgwM4n2zwAkrpot66IT4AZuOI33DqQH3CrLINryHUG7j6vxDrMQAddxW5Mc3QImEHd5Cgw2vaWhzbzVx75e9N0TrBPpI",
    },
    seo:{
        title:'Laura Farías',
        description:'',
        url:'',
        img:'',
        image_url:'',
        site_name:'',
        locale:'',
        type:'website'
    },
    investor:{},
    modules: [],
}

const CHANGE_API = 'CHANGE_API'
const GET_INVESTOR = 'GET_INVESTOR'
const GET_CONFIGURATIONS = "GET_CONFIGURATIONS"

export default function settingsReducer(state = dataInicial, action){
    switch(action.type){
        case CHANGE_API : {
            return {...state,
                keys : {...state.keys,
                tokko: action.payload
                } 
            }
        }
        case GET_INVESTOR : {
            return {...state,
                investor: action.payload
            }
        }
        case GET_CONFIGURATIONS: {
            return { ...state, modules: action.payload }
        }
        default:
            return state
    }
}

// 2e4da1b9a9b3c724ab1cbfa7c1f92ab291ba1a2b

export const CHANGE_APIKEY_ACTION = (api_key) => async (dispatch, getState) => {
    dispatch({
        type: CHANGE_API,
        payload: api_key
    })
}

export const GET_INVESTOR_ACTION = () => async (dispatch, getState) => {
    try {
        const res = await axios.get(`https://mediacore.app/api/investor/configuracion_usuarios/publica/?client=6`)
        dispatch({
            type: GET_INVESTOR,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
    }
}

export const GET_CONFIGURATIONS_ACTION = () => async (dispatch, getState) => {
    try {
      const res = await axios.get(`https://mediacore.app/api/core/custom-login/configuraciones/?client=6`)
      dispatch({
        type: GET_CONFIGURATIONS,
        payload: res.data,
      })
    } catch (error) {
      console.log(error)
    }
}